export const ACCOUNT_TYPE_BANK = "BANK";
export const ACCOUNT_JOB_NAME = "Accounts";
export const XERO_SYNC_COMPLETED_JOB_NAME = "XeroSyncCompleted";
export const XERO_SYNC_STARTED_JOB_NAME = "XeroSyncStarted";
export const XERO_SYNC_DELETED = "XeroSyncDeleted";
export const ACCOUNTING_SYNC_FAILED = "AccountingSyncFailed";
export const ACCOUNTING_SYNC_CONNECTION_STATUS_UPDATE = "AccountingSyncConnectionStatusUpdate";
/**
 * These values come from: app/Figured/Packages/XeroSync/SyncRunner.php
 */
export const XERO_SYNC_STATUS = Object.freeze({
    WAITING:      0,
    RUNNING:      1,
    ERROR:        2,
    COMPLETE:     3,
    INCOMPLETE:   4,
    DISCONNECTED: 5,
});
